import { Route, Routes } from 'react-router-dom'

import ScrollToTop from './ScrollToTop'
import HomePage from './pages/HomePage'
import AboutUs from './pages/AboutUs'
import Contact from './pages/Contact'

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  )
}

export default App

import { Link } from 'react-router-dom'

import classes from './PopupContactUs.module.css'
import xIcon from '../../photos/xIcon.png'

function PopupContactUs(props) {
  const closePopup = () => {
    props.popupHandler()
  }

  return (
    <div className={classes.bg}>
      <div className={classes.main}>
        <div className={classes.backgroundImageContainer}></div>
        <div className={classes.blackBG}>
          <button onClick={closePopup}>
            <img src={xIcon} alt="X" />
          </button>
          <div className={classes.main_popup}>
            <h2 className={classes.h2}>Hi Gorgeous </h2>
            <div className={classes.p_div}>
              <p className={classes.p} dir="rtl">
                כל היפות אצלינו! <br />
                נשמח להכיר אותך <br />
                שלחי לנו הודעה וניצור איתך קשר
              </p>
            </div>
            <Link to="/contact" className={classes.a_contact}>
              ליצירת קשר לחצי כאן
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupContactUs

import { useEffect, useState } from 'react'
import classes from './ImageNotMove.module.css'

function ImageNotMove() {
  const [width, setWidth] = useState(true)

  useEffect(() => {
    if (window.innerWidth < 850) setWidth(false)
  }, [])

  return (
    <div className={classes.main}>
      {!width && (
        <h2>
          CLASSIC <br></br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;STYLES
        </h2>
      )}
      {width && <div className={classes.main1}></div>}
      {width && (
        <div className={classes.exstra}>
          <h3>
            <span>CLASSIC</span>&nbsp; STYLES
          </h3>
          <div className={classes.main2}></div>
        </div>
      )}
    </div>
  )
}

export default ImageNotMove

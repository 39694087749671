import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import NavbarOpen from './NavbarOpen'
import icons from '../../photos/iconsMenu1.png'

import classes from './Navbar.module.css'

function Navbar(props) {
  const [isCliced, setIsCliced] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const isClicedBtn = () => {
    setIsCliced(!isCliced)
  }

  useEffect(() => {
    if (props.notColorChange) {
      setScrolled(true)
    } else {
      const handleScroll = () => {
        if (window.scrollY >= 160) {
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className={`${classes.main} ${!props.border ? classes.noneBorder : ''}`}
    >
      {!isCliced && (
        <nav className={`${scrolled ? classes.scrolled : ''}`}>
          <div className={classes.main_navbar}>
            <button className={classes.btn} onClick={isClicedBtn}>
              <img className={classes.img} src={icons} alt="icon" />
            </button>
            <div className={classes.company_name}>
              <Link to="/">S.MARIAGE</Link>
            </div>
          </div>
        </nav>
      )}
      {isCliced && <NavbarOpen isClicedBtn={isClicedBtn} />}
    </div>
  )
}

export default Navbar

import { useState, useEffect, useRef } from 'react'

import mainVideo from '../photos/Mainvideo2.mp4'

import mainImg from '../photos/section5-new.webp'

import classes from './video.module.css'

function Video() {
  const [isVisible, setIsVisible] = useState(true)
  const videoRef = useRef(null)
  const videoParentRef = useRef()
  const [shouldUseImage, setShouldUseImage] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    })
    observer.observe(videoRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0
  }

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0]

      if (player) {
        player.controls = false
        player.playsinline = true
        player.muted = true
        player.setAttribute('muted', '')
        player.autoplay = true

        setTimeout(() => {
          const promise = player.play()

          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = 'none'
                setShouldUseImage(true)
              })
          }
        }, 0)
      }
    }
  }, [videoParentRef])

  return (
    <div ref={videoRef} className={classes.main}>
      {isVisible ? (
        shouldUseImage ? (
          <img src={mainImg} alt="wideo" />
        ) : (
          <div
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
            <video class="${classes.video}" autoplay muted playsinline loop>
              <source src="${mainVideo}" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          `,
            }}
          ></div>
        )
      ) : null}

      <div className={classes.sentences}>
        <h2> BEGIN YOUR BRIDEL JOURNEY&nbsp;</h2>
        <h2> WITH US.</h2>

        <p>Luxury, Reimagined.</p>
      </div>
    </div>
  )
}

export default Video

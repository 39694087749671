import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Navbar from '../component/layout/Navbar'
import Video from '../component/Video'
import Slideshow from '../component/Slideshow'
import SlideshowWide from '../component/SlideshowWide'
import Footer from '../component/Footer'
import MainParagraphs from '../component/MainParagraphs'
import Paragraph2 from '../component/Paragraph2'
import ImageNotMove from '../component/ImageNotMove'
import FabricVideo from '../component/FabricVideo'
import PopupContactUs from '../component/layout/PopupContactUs'
import NavbarWide from '../component/layout/NavbarWide'

import whatsapp from '../photos/whatsapp2.png'

import classes from './HomePage.module.css'

function HomePage() {
  const [popupOpen, setPopupOpen] = useState(false)
  const [width, setWidth] = useState(true)
  const [cameUpOnce, setCameUpOnce] = useState(false)

  const popupHandler = () => {
    setPopupOpen(!popupOpen)
  }

  useEffect(() => {
    if (window.innerWidth < 750) setWidth(false)
  }, [])

  // useEffect(() => {
  //   const timeId = setTimeout(() => {
  //     popupHandler()
  //   }, 10000)
  //   return () => clearTimeout(timeId)
  // }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const totalHeight = document.documentElement.scrollHeight
      const viewportHeight = window.innerHeight
      const isScrolledToBottom = scrollPosition + viewportHeight >= totalHeight

      if (!cameUpOnce && isScrolledToBottom) {
        setCameUpOnce(true)
        setPopupOpen(true)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [cameUpOnce])

  return (
    <div className={classes.home_Page}>
      {popupOpen && <PopupContactUs popupHandler={popupHandler} />}
      {!width && <Navbar border={true} />}
      {width && <NavbarWide />}
      <Video />
      <MainParagraphs />
      <ImageNotMove />
      {!width && <Slideshow />}
      {width && <SlideshowWide />}
      {!width && <FabricVideo />}
      <Paragraph2 />
      <Footer />
      <div className={classes.contact}>
        <Link to="https://wa.link/5yynca" target="_blank">
          <img src={whatsapp} alt="" />
        </Link>
      </div>
    </div>
  )
}

export default HomePage

import { useState, useEffect } from 'react'

import imageSlide11 from '../photos/slide/4-1-1.webp'
import imageSlide21 from '../photos/slide/4-1-2.webp'
import imageSlide31 from '../photos/slide/4-1-3.webp'

import imageSlide12 from '../photos/slide/4-2-1.webp'
import imageSlide22 from '../photos/slide/4-2-2.webp'
import imageSlide32 from '../photos/slide/4-2-3.webp'

import imageSlide13 from '../photos/slide/4-3-1.webp'
import imageSlide23 from '../photos/slide/4-3-2.webp'
import imageSlide33 from '../photos/slide/4-3-3.webp'

import imageSlide14 from '../photos/slide/4-4-1.webp'
import imageSlide24 from '../photos/slide/4-4-2.webp'
import imageSlide34 from '../photos/slide/newImg1.webp'

import classes from './Slideshow.module.css'

const Slideshow = () => {
  const [images1, setImages1] = useState([
    imageSlide11,
    imageSlide21,
    imageSlide31,
  ])
  const [images2, setImages2] = useState([
    imageSlide12,
    imageSlide22,
    imageSlide32,
  ])
  const [images3, setImages3] = useState([
    imageSlide13,
    imageSlide23,
    imageSlide33,
  ])
  const [images4, setImages4] = useState([
    imageSlide14,
    imageSlide24,
    imageSlide34,
  ])
  const [currentImageIndex1, setCurrentImageIndex1] = useState(0)
  const [currentImageIndex2, setCurrentImageIndex2] = useState(0)
  const [currentImageIndex3, setCurrentImageIndex3] = useState(0)
  const [currentImageIndex4, setCurrentImageIndex4] = useState(0)

  useEffect(() => {
    let counter = 0
    let time = 2900
    const interval = setInterval(() => {
      setCurrentImageIndex1((currentImageIndex1) =>
        currentImageIndex1 === images1.length - 1 ? 0 : currentImageIndex1 + 1
      )
      counter++
      if (counter === 4) {
        time = time + 400
        counter = 0
      }
    }, time)
    return () => clearInterval(interval)
  }, [images1.length])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex2((currentImageIndex2) =>
        currentImageIndex2 === images2.length - 1 ? 0 : currentImageIndex2 + 1
      )
    }, 3000)
    return () => clearInterval(interval)
  }, [images2.length])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex3((currentImageIndex3) =>
        currentImageIndex3 === images3.length - 1 ? 0 : currentImageIndex3 + 1
      )
    }, 3000)
    return () => clearInterval(interval)
  }, [images3.length])

  useEffect(() => {
    let counter = 0
    let time = 2900
    const interval = setInterval(() => {
      setCurrentImageIndex4((currentImageIndex4) =>
        currentImageIndex4 === images4.length - 1 ? 0 : currentImageIndex4 + 1
      )
      counter++
      if (counter === 4) {
        time = time + 400
        counter = 0
      }
    }, time)

    return () => clearInterval(interval)
  }, [images4.length])

  return (
    <div className={classes.slideshowContainer}>
      <div className={classes.slideshow1}>
        {images1.map((image, index) => (
          <img
            key={index}
            className={`${classes.slideshowImage} ${
              index === currentImageIndex1 ? classes.visible : classes.hidden
            }`}
            src={image}
            alt="Slideshow"
          />
        ))}
      </div>

      <div className={classes.slideshow3}>
        {images3.map((image, index) => (
          <img
            key={index}
            className={`${classes.slideshowImage} ${
              index === currentImageIndex3 ? classes.visible : classes.hidden
            }`}
            src={image}
            alt="Slideshow"
          />
        ))}
      </div>
      <div className={classes.slideshow2}>
        {images2.map((image, index) => (
          <img
            key={index}
            className={`${classes.slideshowImage} ${
              index === currentImageIndex2 ? classes.visible : classes.hidden
            }`}
            src={image}
            alt="Slideshow"
          />
        ))}
      </div>
      <div className={classes.slideshow4}>
        {images4.map((image, index) => (
          <img
            key={index}
            className={`${classes.slideshowImage} ${
              index === currentImageIndex4 ? classes.visible : classes.hidden
            }`}
            src={image}
            alt="Slideshow"
          />
        ))}
      </div>
    </div>
  )
}

export default Slideshow

import { useState, useEffect } from 'react'

import Navbar from '../component/layout/Navbar'
import NavbarWide from '../component/layout/NavbarWide'
import Footer from '../component/Footer'

import classes from './ThankYouPage.module.css'

function ThankYouPage() {
    const [width, setWidth] = useState(true)

    useEffect(() => {
        if (window.innerWidth < 750) setWidth(false)
    }, [])

    return (
        <>
            {!width && <Navbar notColorChange={false} border={false} />}
            {width && <NavbarWide />}

            <div className={classes.main} dir='rtl'>
                <div className={classes.centeredbox}>
                    <div>
                        <h1>תודה רבה!</h1>
                        <p>ניצור איתך קשר בהקדם</p>
                    </div>
                </div>
            </div>
            <div className={classes.spacing}></div>
            <Footer />
        </>
    )
}

export default ThankYouPage;
import classes from './paragraph2.module.css'

function Paragraph2() {
  return (
    <div className={classes.main}>
      <h2>Your ideal bridal experience</h2>
      <p dir="rtl">
        חווית השכרה מיוחדת ושונה של שמלות כלה עם דגש על אווירה כיפית וקלילה.
        כמובן, שמלות מהממות שמדברות בדיוק את מי שאת!
      </p>
    </div>
  )
}

export default Paragraph2

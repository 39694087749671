import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import img1 from '../photos/6-4-1.webp'
import img2 from '../photos/9-2.webp'
import img3 from '../photos/9-1.webp'
import img4 from '../photos/7-extra-1.webp'
import img5 from '../photos/9new.webp'
import whatsapp from '../photos/whatsapp.png'
import instagram from '../photos/instagram.png'

import classes from './Footer.module.css'

function Footer() {
  const [width, setWidth] = useState(true)

  useEffect(() => {
    if (window.innerWidth < 850) setWidth(false)
  }, [])

  return (
    <footer className={classes.main}>
      <div className={classes.img}>
        {width && <img src={img4} alt="img" />}
        <img src={img1} alt="img" />
        <img src={img2} alt="img" />
        <img src={img3} alt="img" />
        {width && <img src={img5} alt="img" />}
      </div>
      <div className={classes.main_info}>
        <div className={classes.top}>
          <h2>ON INSTEGRAM</h2>
          <p dir="rtl">הישארי מעודכנת בדגמים חדשים, השראה והרבה סטייל</p>

          <p className={classes.img_parent}>
            <img
              className={classes.img_whatsapp}
              src={instagram}
              alt="instagram"
            />
            &nbsp;
            <Link
              to="https://www.instagram.com/smariage_/"
              target="_blank"
              className={classes.smariage}
            >
              SMARIAGE_
            </Link>
          </p>
        </div>

        <div className={classes.dit}>
          <h2> צרי איתנו קשר</h2>
          <ul>
            <li className={classes.img_parent}>
              <Link to="https://wa.link/rj43ob" target="_blank">
                <span className={classes.phone}>052-5287443</span>
              </Link>
              &nbsp;|&nbsp;
              <Link to="https://wa.link/rj43ob" target="_blank">
                <span className={classes.phone}>050-6716402</span>
              </Link>
              &nbsp;
              <img
                className={classes.img_whatsapp}
                src={whatsapp}
                alt="whatsapp"
              />
            </li>

            <li dir="rtl" className={classes.address}>
              נחל עין גדי 44 רמב״ש
            </li>
            <li>SMARIAGE44@GMAIL.COM</li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import NavbarOpen from './NavbarOpen'
import icons from '../../photos/iconsMenu1.png'

import classes from './NavbarWide.module.css'

function NavbarWide(props) {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    if (props.notColorChange) {
      setScrolled(true)
    } else {
      const handleScroll = () => {
        if (window.scrollY >= 160) {
          setScrolled(true)
        } else {
          setScrolled(false)
        }
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div>
      <nav className={`${classes.main} ${scrolled ? classes.scrolled : ''}`}>
        <div
          className={`${classes.main_navbar} ${
            scrolled ? classes.main_navbar_scrolled : ''
          }`}
        >
          <ul>
            <li>
              <Link to="/">Home Page</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
          <Link to="/" className={classes.company_name}>
            S.MARIAGE
          </Link>
        </div>
      </nav>
    </div>
  )
}

export default NavbarWide

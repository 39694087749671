import { useState, useEffect } from 'react'
import classes from './MainParagraphs.module.css'

function MainParagraphs() {
  const [isScroll, setIsScroll] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 250) {
        setIsScroll(true)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className={classes.main}>
      <div className={isScroll ? classes.scroll : ''}>
        <h2>The Process</h2>
        <p dir="rtl">
          מוזמנת להגיע לפגישה אישית בסטודיו שלנו ברמת בית שמש ולהתרשם מקונספט
          מנצח של לבוא - ולהתאהב -ולקחת. כל מה שאת צריכה זה להגיע לפגישה בשלה
          לקבל החלטות וכמובן עם המלוות שחשובות לך שיהיו איתך בזמן המדידות ואנחנו
          נדאג כבר לכל השאר.
        </p>
      </div>

      <div className={isScroll ? classes.scroll : ''}>
        <h2>Our Motto</h2>
        <p dir="rtl" className={classes.p1}>
          ההחלטה מה ללבוש ביום הגדול שלך היא בהחלט אחת ההחלטות החשובות. אנחנו
          יודעות לעשות בדיוק את מה שצריך, כדי שתוכלי ליהנות מהדרך ולהיות מרוצה
          עד הגג. חשוב לנו להתאים את העיצוב לאופי שלך, כדי שהשמלה תבטא את
          אישיותך ואת הקסם הייחודי שיש רק בך.
        </p>
      </div>
      <div className={isScroll ? classes.scroll : ''}>
        <h2>Made With Love</h2>
        <p dir="rtl" className={classes.p1}>
          אנחנו שתי אחיות נועה ועדי מעצבות בנשמה, חיות ונושמות עיצוב בעלות
          ניסיון רב, עם מערך שלם שעומד מאחורינו. אנחנו יודעות להתאים את סוגי
          הבדים ואת הדגם למבנה הגוף, מה שיוצר את המראה המושלם עבורך וכל זה עם
          חוויה רגועה, נעימה ובלתי נשכחת!
        </p>
      </div>
    </div>
  )
}

export default MainParagraphs

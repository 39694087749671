import { useState, useEffect } from 'react'
import { useForm } from '@formspree/react'

import Navbar from '../component/layout/Navbar'
import NavbarWide from '../component/layout/NavbarWide'
import Footer from '../component/Footer'
import ThankYouPage from './ThankYouPage'

import arrowLeft from '../photos/arrowLeft.png'
import classes from './Contact.module.css'

function Contact() {
  const [width, setWidth] = useState(true)
  const [sentSuccessfully, setSentSuccessfully] = useState(false)
  const [state, handleSubmit] = useForm('xyyalddz')

  useEffect(() => {
    if (window.innerWidth < 750) setWidth(false)
  }, [])

  useEffect(() => {
    if (state.succeeded) {
      setSentSuccessfully(true)
    }
    const timeId = setTimeout(() => {
      setSentSuccessfully(false)
    }, 4000)
    return () => clearTimeout(timeId)
  }, [state.succeeded])

  if (state.succeeded) {
    return <ThankYouPage />;
  }

  return (
    <>
      {!width && <Navbar notColorChange={false} border={false} />}
      {width && <NavbarWide />}

      <div className={classes.main}>
        <div className={classes.mian_2}>
          <form onSubmit={handleSubmit}>
            <h2>Contact Us</h2>
            <p dir="rtl">הי! איזה כיף שאת פה. השאירי פרטים ונחזור אלייך בהקדם</p>
            <p>שם מלא</p>
            <input type="text" name="name" id="name" placeholder="FULL NAME" />
            <br />
            <p>טלפון</p>
            <input
              type="tel"
              name="phone number"
              id="phone number"
              placeholder="PHONE NUMBER"
            />
            <br />
            <p>תאריך חתונה</p>
            <input
              type="text"
              name="wedding date"
              id="wedding date"
              placeholder="WEDDING DATE"
            />
            <br />
            <p>דואר אלקטרוני</p>
            <input type="email" name="email" id="email" placeholder="Email" />
            <br />
            <textarea
              cols="30"
              rows="10"
              name="message"
              id="message"
              placeholder="ANYTHING ELSE YOU WANT TO SAY?"
            ></textarea>
            <br />

            {!sentSuccessfully && (
              <button
                className={classes.submit}
                type="submit"
                disabled={state.submitting}
              >
                <img src={arrowLeft} alt="" className={classes.arrow} />
                שליחה
              </button>
            )}
          </form>
        </div>
      </div>
      <div className={classes.spacing}></div>
      <Footer />
    </>
  )
}

export default Contact

import { Link } from 'react-router-dom'

import xIcon from '../../photos/xIcon.png'
import logo2 from '../../photos/logo2.png'
import whatsapp from '../../photos/whatsapp.png'
import instagram from '../../photos/instagram.png'
import classes from './NavbarOpen.module.css'

function NavbarOpen(props) {
  const clicedClose = () => {
    props.isClicedBtn()
  }
  return (
    <div className={classes.main_navbar}>
      <div className={classes.x_father}>
        <div></div>
        <button onClick={clicedClose} className={classes.x}>
          <img src={xIcon} alt="x" />
        </button>
      </div>
      <div className={classes.logo}>
        <img src={logo2} alt="logo" />
      </div>
      <ul className={classes.first_ul}>
        <li>
          <Link to="/">Home Page</Link>
        </li>
        <li>
          <Link to="/about-us">About Us</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>
      <ul className={classes.conection_ul}>
        <li>
          <Link to="https://wa.link/rj43ob">
            <img src={whatsapp} alt="whatsapp" />
          </Link>
        </li>
        <li>
          <Link to="https://www.instagram.com/smariage_/">
            <img src={instagram} alt="instagram" />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default NavbarOpen

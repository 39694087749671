import { useState, useEffect, useRef } from 'react'

import fabricVideo from '../photos/New2.mp4'
import mainImg from '../photos/section5-2.webp'

import classes from './fabricVideo.module.css'

function FabricVideo() {
  const [isVisible, setIsVisible] = useState(false)
  const videoRef = useRef(null)
  const videoParentRef = useRef()
  const [shouldUseImage, setShouldUseImage] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    })
    observer.observe(videoRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('safari') > -1 && ua.indexOf('chrome') < 0
  }

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0]

      if (player) {
        player.controls = false
        player.playsinline = true
        player.muted = true
        player.setAttribute('muted', '')
        player.autoplay = true

        setTimeout(() => {
          const promise = player.play()

          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = 'none'
                setShouldUseImage(true)
              })
          }
        }, 0)
      }
    }
  }, [videoParentRef])

  return (
    <>
      <div ref={videoRef} className={classes.main_size}>
        <div className={classes.main}>
          {isVisible ? (
            shouldUseImage ? (
              <img src={mainImg} alt="wideo" />
            ) : (
              <div
                ref={videoParentRef}
                dangerouslySetInnerHTML={{
                  __html: `
            <video class="${classes.video}" autoplay muted playsinline loop>
              <source src="${fabricVideo}" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          `,
                }}
              ></div>
            )
          ) : null}

          {/* {isVisible && (
            <video className={classes.video} autoPlay muted loop>
              <source src={fabricVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )} */}

          <div className={classes.p}>
            <h2>CHECK OUT MODEST</h2>
            <p dir="rtl">
              מבחר של שמלות כלה צנועות נוטפות סטייל להשכרה בעיצובים חדשניים
              ומדוייקים! בנוסף ניתן לתפור בהתאמה אישית ולערוך התאמות ושינויים על
              מנת להתאים את השמלה בדיוק בשבילך!
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default FabricVideo

import { useState, useEffect } from 'react'

import imageSlide11 from '../photos/4-1-1.webp'
import imageSlide21 from '../photos/4-1-2.webp'
import imageSlide31 from '../photos/4-1-3.webp'
import imageSlide93 from '../photos/9-3.webp'

import imageSlide12 from '../photos/6-1-1.webp'
import imageSlide22 from '../photos/4-2-2.webp'
import imageSlide32 from '../photos/4-2-3.webp'
import imageSlide94 from '../photos/9-4.webp'

import imageSlide13 from '../photos/section3.webp'
import imageSlide23 from '../photos/4-3-2.webp'
// import imageSlide33 from '../photos/4-4-3.webp'
import imageSlide33 from '../photos/newImg1.webp'
import imageSlide613 from '../photos/6-1-3.webp'

import classes from './SlideshowWide.module.css'

const SlideshowWide = () => {
  const [images1, setImages1] = useState([
    imageSlide12,
    imageSlide21,
    imageSlide31,
    imageSlide93,
  ])
  const [images2, setImages2] = useState([
    imageSlide11,
    imageSlide22,
    imageSlide32,
    imageSlide613,
  ])
  const [images3, setImages3] = useState([
    imageSlide13,
    imageSlide23,
    imageSlide33,
    imageSlide94,
  ])

  const [currentImageIndex1, setCurrentImageIndex1] = useState(0)
  const [currentImageIndex2, setCurrentImageIndex2] = useState(0)
  const [currentImageIndex3, setCurrentImageIndex3] = useState(0)

  useEffect(() => {
    let counter = 0
    let time = 2900
    const loop = async () => {
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, time))
        time = 2900
        setCurrentImageIndex1((currentImageIndex1) =>
          currentImageIndex1 === images1.length - 1 ? 0 : currentImageIndex1 + 1
        )
        counter++
        if (counter === 4) {
          time = time + 400
          counter = 0
        }
      }
    }
    loop()
  }, [images1.length])

  useEffect(() => {
    const loop = async () => {
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, 3000))
        setCurrentImageIndex2((currentImageIndex2) =>
          currentImageIndex2 === images2.length - 1 ? 0 : currentImageIndex2 + 1
        )
      }
    }
    loop()
  }, [images2.length])

  useEffect(() => {
    let counter = 0
    let time = 3100
    const loop = async () => {
      while (true) {
        await new Promise((resolve) => setTimeout(resolve, time))
        time = 3100
        setCurrentImageIndex3((currentImageIndex3) =>
          currentImageIndex3 === images3.length - 1 ? 0 : currentImageIndex3 + 1
        )

        counter++
        if (counter === 4) {
          time = time - 400
          counter = 0
        }
      }
    }
    loop()
  }, [images3.length])
  return (
    <div className={classes.slideshowContainer}>
      <div className={classes.slideshow1}>
        {images1.map((image, index) => (
          <img
            key={index}
            className={`${classes.slideshowImage} ${
              index === currentImageIndex1 ? classes.visible : classes.hidden
            }`}
            src={image}
            alt="Slideshow"
          />
        ))}
      </div>

      <div className={classes.slideshow3}>
        {images3.map((image, index) => (
          <img
            key={index}
            className={`${classes.slideshowImage} ${
              index === currentImageIndex3 ? classes.visible : classes.hidden
            }`}
            src={image}
            alt="Slideshow"
          />
        ))}
      </div>
      <div className={classes.slideshow2}>
        {images2.map((image, index) => (
          <img
            key={index}
            className={`${classes.slideshowImage} ${
              index === currentImageIndex2 ? classes.visible : classes.hidden
            }`}
            src={image}
            alt="Slideshow"
          />
        ))}
      </div>
    </div>
  )
}

export default SlideshowWide

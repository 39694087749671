import { useState, useEffect, useRef } from 'react'

import Navbar from '../component/layout/Navbar'
import NavbarWide from '../component/layout/NavbarWide'
import Footer from '../component/Footer'
import arrow from '../photos/arrow.png'

import classes from './AboutUs.module.css'

import img2 from '../photos/aboutUs2.JPG.webp'
import mainImg from '../photos/newGood.webp'

function AboutUs() {
  const [width, setWidth] = useState(true)
  const [isVisible1, setIsVisible1] = useState(false)
  const [isVisible2, setIsVisible2] = useState(false)
  const imgRef1 = useRef(null)
  const imgRef2 = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        setIsVisible1(false)
      } else {
        setIsVisible1(true)
      }
    })
    observer.observe(imgRef1.current)
    return () => {
      observer.disconnect()
    }
  }, [])
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        setIsVisible2(false)
      } else {
        setIsVisible2(true)
      }
    })
    observer.observe(imgRef2.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (window.innerWidth < 750) setWidth(false)
  }, [])
  return (
    <>
      {!width && <Navbar notColorChange={true} />}
      {width && <NavbarWide notColorChange={true} />}
      <div className={classes.main} ref={imgRef1}>
        {isVisible1 && <img src={mainImg} alt="mainImg" />}
        <div className={classes.layout}>
          <p>this is</p>
          <h2>Our story</h2>
          <p>
            <img src={arrow} alt="arrow" />
          </p>
        </div>
      </div>
      <div className={classes.main_p}>
        <p dir="rtl">
          אז בואו נתחיל,
          <br />
          הסטודיו שלנו הינו סטודיו בוטיק לעיצוב <br />
          שמלות כלה ועובד בשיטת השכרה.
          <br />
          בסטודיו שלנו קיים מגוון חדשני של שמלות <br />
          כלה בסטייל. בנוסף, אנו מתמקדות בתפירה
          <br /> ובעיצוב אישי.
        </p>
        <div className={classes.img_3}></div>
        <p dir="rtl" className={classes.main_p2}>
          אנחנו שתי אחיות נועה ועדי, מעצבות בנשמה, חיות ונושמות עיצוב. בעלות
          ניסיון רב עם מערך שלם שעומד ועומל מאחורינו. באת לבעלות מקצוע, למי
          שמבינות ורואות את המוצר המוגמר עוד בשלב הראשוני של התהליך.
          <br /> אנחנו יודעות להתאים את סוגי הבדים לדגם ואת הדגם למבנה הגוף. מה
          שיוצר מראה מושלם שיבליט את היופי שלך.
        </p>
      </div>
      <div className={classes.img_2} ref={imgRef2}>
        {isVisible2 && <img src={img2} alt="img" />}
      </div>
      <div className={classes.second_p}>
        <p dir="rtl">
          מאחורי כל דגם בסטודיו שלנו ישנה מחשבה רבה של שתינו, כל חוט וכל פינינה
          נבחרים בקפידה רבה.
          <br /> בואי להינות איתנו מהתהליך, מחוויה רגועה, נעימה ובלתי נשכחת
          והתוצאה המושלמת כמובן.
        </p>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs
